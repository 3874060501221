import * as React from 'react';
import { ElementType, HTMLAttributes } from 'react';

interface HeaderProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
}

interface ContentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
}

interface FooterProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
}

interface OwnProps {
  header: HeaderProps;
  content: ContentProps;
  footer: FooterProps;
}

const StickyFooterLayout = ({ header, content, footer }: OwnProps) => {
  const {
    as: HeaderTag = 'header',
    className: headerClassName,
    ...headerProps
  } = header;
  const {
    as: ContentTag = 'main',
    className: contentClassName,
    ...contentProps
  } = content;
  const {
    as: FooterTag = 'footer',
    className: footerClassName,
    ...footerProps
  } = footer;

  return (
    <div className="flex flex-col h-full">
      <HeaderTag className={headerClassName} {...headerProps} />
      <ContentTag className={contentClassName} {...contentProps} />
      <FooterTag
        className={`grow${footerClassName ? ' ' + footerClassName : ''}`}
        {...footerProps}
      />
    </div>
  );
};

export default StickyFooterLayout;
