import * as React from 'react';

const Creator = () => {
  return (
    <div className="p-6 text-right">
      <a
        className="text-xs text-white no-underline hover:text-amber-300 active:text-amber-300 focus:text-amber-300 focus:outline-none transition ease-in-out duration-500"
        href="https://moments-fotografie.com/"
        target="_blank"
      >
        <span className="hidden sm:inline">
          Website Fotografie, Konzeption, Gestaltung & Programmierung:&nbsp;
        </span>
        <span className="sm:hidden">Website Programmierung:&nbsp;</span>
        moments FOTOGRAFIE
      </a>
    </div>
  );
};

export default Creator;
