import * as React from 'react';
import { Link } from 'gatsby';

const Copyright = () => {
  return (
    <div className="flex flex-wrap justify-center items-center py-20 bg-neutral-900">
      <Link
        className="py-1 px-3 text-slate-300 hover:scale-110 ease-in-out duration-200"
        to="/impressum"
      >
        Impressum
      </Link>
      <span className="h-6 border border-gray-500" />
      <Link
        className="py-1 px-3 text-slate-300 hover:scale-110 ease-in-out duration-200"
        to="/datenschutz"
      >
        Datenschutz
      </Link>
      <a
        className="w-full text-center text-sm font-light text-slate-300 "
        href="https://moments-fotografie.com/"
        target="_blank"
      >
        &copy; {new Date().getFullYear()} moments FOTOGRAFIE - All rights
        reserved.
      </a>
    </div>
  );
};

export default Copyright;
