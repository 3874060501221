import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import StickyFooterLayout from './stickyFooterLayout';
import Footer from './footer/footer';

interface OwnProps {
  children: React.ReactNode;
}

const Layout = ({ children }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <StickyFooterLayout
      header={{
        as: 'header',
        className: 'mb-6 bg-teal-600',
        children: (
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        ),
      }}
      content={{ children }}
      footer={{
        as: 'footer',
        className: 'mt-8 bg-neutral-700',
        children: <Footer />,
      }}
    />
  );
};

export default Layout;
