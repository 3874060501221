import * as React from 'react';
import Creator from './creator';
import Copyright from './copyright';

const Footer = () => (
  <>
    <Copyright />
    <Creator />
  </>
);

export default Footer;
